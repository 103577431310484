import { BasicsClass } from '@/common/BasicsClass';
export class StudentSignupListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getStudentSignupRecords', {
            url: '/admin/studentEnroll/index',
            name: 'list',
            label: '列表'
        }],
        ['exportStudentSignupRecords',  {
            url: '/admin/studentEnroll/export',
            name: 'export-excel',
            label: '导出'
        }],
       
    ])

    // 学员报名记录列表
    public getStudentSignupRecords(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getStudentSignupRecords').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 导出学员报名记录列表
    public exportStudentSignupRecords(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('exportStudentSignupRecords').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}